import React, { useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import UKRegions from "../../assets/map/uk-regions/index"
import "../../assets/map/singleRegionMap.scss";
import PageWrapper from "../../components/PageWrapper";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title, Button } from "../../components/Core";
import Seo from "./../../components/Seo";
import { navigate } from "gatsby-link";
import { BigTownCard } from "../../components/TownCard/TownCard";
import NetomniaRegions from "../../sections/index/NetomniaRegions";
import BlogList from "../../sections/blog/BlogList";
import { getProperty } from "../../utils/helperFn";
import { set } from "ol/transform";

const StrapiRegion = (props) => {

    const Region = props.data.strapiRegions

    const Towns = props.data.strapiRegions.towns

    console.log(Region);
	const contactText = props.data.strapiContactPage;
    // const region = UKRegions.locations.find(region => region.id === Region.Slug)
   /* const map = {
        "label": "Map of UK Regions",
        "viewBox": Region.viewbox,
        "preserveAspectRatio": "xMidYMid meet",
        "locations": [region]
    }*/


    /* If there are no towns available in the Region, redirect to home screen. */
    useEffect(() => {
        if (Towns.length === 0 && window) navigate('/')
    }, [Towns]);

    /*const renderTownCards = () => {

        if (Towns.length > 0) {
            return (
                Towns.map(Town => (
                    <BigTownCard townImg={Town.Featured_image} title={Town.Name} link={Town.Slug} className="mt-4">
                        <span className="locationTitle">
                            <i className="fa fa-map-marker-alt mr-2" style={{ opacity: '0.7' }} />
                            {Region.Name}, United Kingdom
                        </span>
                    </BigTownCard>
                ))


            )
        } else
            return <></>

    }*/
    return (
        <>
            <Seo page={Region.Slug} />
            {/*<PageWrapper footerDark>

                <Section
                    pt={["160px!important", null, "200px!important"]}
                    pb={["20px!important", null, "100px!important"]}
                >
                    <Container>
                        <Row>
                            <Col sm={12} className="text-center">
                                <Title style={{ fontSize: '60px' }}>{Region.Name}</Title>
                                <Text>These are the cities we covered in {Region.Name} area so far</Text>
                                <SVGMap map={map}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} lg={7} className="p-3 mt-5 mx-auto">
                                {
                                    renderTownCards()
                                }
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col sm="12" md="10" className="mb-5 mx-auto text-center">
                                <Button onClick={() => navigate('/areas')}>
                                    Back to Areas
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Section>
            </PageWrapper>*/}
			<PageWrapper footerDark>
				<div className="pt-5"/>
				<NetomniaRegions mapOnly={false} isNew={true} region={Region}/>
			</PageWrapper>
			<BlogList articles={props.data} enquiryHtml={contactText.enquiry_notice} page="news"/>
        </>
    );

}

export const data = graphql`
        query CombinedQuery($Slug: String) {
  strapiRegions(Slug: {eq: $Slug}) {
    Slug
    Name
    Viewbox
    towns {
      Region
      Name
      Slug
      region
      Featured_image {
        childImageSharp {
          fluid(maxHeight: 120, quality: 85) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  
  allStrapiNewsArticles(limit: 3, sort: {order: DESC, fields: backdate}) {
    edges {
      node {
        id
        strapiId
        backdate
        published_at
        title
        content
        slug
        image_featured {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
  
  strapiContactPage {
    enquiry_notice
  }
  
  strapiPageHeadings(slug: { eq: "news" }) {
    h1
  }
}
    `

export default StrapiRegion
